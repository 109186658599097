import Nav from "../nav";
import Footer from "../footer";

import styles from "./layout.module.scss";

export default function Layout({ children }) {
  return (
    <div>
      <Nav />

      <main className={styles.Main}>{children}</main>

      <Footer />
    </div>
  );
}
