import { useEffect, useState } from "react";

import { getLocations } from "../lib/locations";

export function useLocations() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    getLocations()
      .then((locations) => setData(locations))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    loading,
    error
  };
}
