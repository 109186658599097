import { format, parseISO } from "date-fns";
import React, { useCallback, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import dayPickerStyles from "react-day-picker/dist/style.css";

import IconArrowDown from "../../assets/icons/arrow-down.svg";

import useClickOutside from "../../hooks/useClickOutside";
import Input from "../input";

import styles from "./date-select.module.scss";

const dayPickerClassNames = {
  ...dayPickerStyles,
  root: styles.datePicker,
};

const formatWeekdayName = (day) => {
  return format(day, "EEE");
};

const formatSelectedDate = (day) => {
  if (!day) return "";
  return format(parseISO(day), "PPP");
};

const DatePicker = React.forwardRef(function DatePicker(
  { selectedDate, disabledDates, onChange },
  ref
) {
  const handleDayClick = useCallback(
    (day) => {
      onChange(day);
    },
    [onChange]
  );

  return (
    <DayPicker
      ref={ref}
      classNames={dayPickerClassNames}
      mode="single"
      selected={selectedDate}
      onSelect={handleDayClick}
      weekStartsOn={1}
      disabled={disabledDates}
      formatters={{ formatWeekdayName }}
    />
  );
});

const DateSelect = React.forwardRef(function DateSelect(
  {
    selectedDate,
    disabledDates,
    placeholder,
    inline = false,
    onChange,
    errorText,
  },
  ref
) {
  const [showDayPicker, setShowDayPicker] = useState(false);

  const containerRef = useRef(null);

  const handleDayChange = useCallback(
    (day) => {
      onChange(day);
      setShowDayPicker(false);
    },
    [onChange]
  );

  useClickOutside(() => setShowDayPicker(false), containerRef);

  return (
    // looks like input
    <div ref={containerRef} style={{ width: "100%" }}>
      <div className="relative">
        <Input
          className={styles.DateInput}
          value={formatSelectedDate(selectedDate)}
          readOnly
          onClick={() => setShowDayPicker(true)}
          placeholder={placeholder}
          inline={inline}
          errorText={errorText}
          ref={ref}
        />
        <IconArrowDown className={styles.IconArrow} rel="presentation" />
      </div>

      {showDayPicker && (
        <DatePicker
          selectedDate={selectedDate}
          disabledDates={disabledDates}
          onChange={handleDayChange}
        />
      )}
    </div>
  );
});

export default DateSelect;
