import { createContext, useEffect, useState } from "react";

export function useAcuityAvailableDates(acuityCalendarId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (acuityCalendarId) {
      setLoading(true);
      fetch(`/api/available-dates?calendarID=${acuityCalendarId}`)
        .then((res) => res.json())
        .then((result) => setData(result))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [acuityCalendarId]);

  return {
    data,
    loading,
    error
  };
}

export function useAcuityAvailableTimes(acuityCalendarId, date) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (date && acuityCalendarId) {
      setLoading(true);
      fetch(`/api/available-times?date=${date}&calendarID=${acuityCalendarId}`)
        .then((res) => res.json())
        .then((result) => setData(result))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [date, acuityCalendarId]);

  return {
    data,
    loading,
    error
  };
}

export function useAcuityForms() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true);
      fetch(`/api/forms`)
        .then((res) => res.json())
        .then((result) => setData(result))
        .catch((e) => setError(e))
        .finally(() => setLoading(false));
    }
  }, [data]);

  return {
    data,
    loading,
    error
  };
}
