import React from "react";
import clsx from "clsx";

import styles from "./input.module.scss";

const Input = React.forwardRef(function Input(
  {
    id,
    type = "text",
    className,
    label,
    name,
    helpText,
    errorText,
    successText,
    inline = false,
    ...props
  },
  ref
) {
  return (
    <div className={clsx(styles.FormGroup, inline && styles.Inline)}>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        className={clsx(
          styles.Input,
          errorText && "is-invalid",
          successText && "is-valid",
          className
        )}
        type={type}
        name={name}
        id={id || name}
        ref={ref}
        {...props}
      />

      {helpText && <small className="form-text text-muted">{helpText}</small>}

      {errorText && <div className="text-danger">{errorText}</div>}

      {successText && <div className="text-success">{successText}</div>}
    </div>
  );
});

export default Input;
