import { fetchContent } from "./contentful";

const locationQuery = (slug) => `
query {
  contentTypeLocationCollection(
    where: { slug: "${slug}" }
    limit: 1
  ) {
    items {
      name
      slug
      address1
      address2
      city
      postalCode
      province
      country
      latitude
      longitude
      acuityCalendarId
      googlePlacesId
      document {
        url
      }
      featuredImage {
        url
        description
      }
      gallery {
        title
        galleryItemsCollection {
          items {
            title
            itemType
            image {
              url
              title
              description
            }
            video {
              url
              title
              description
            }
            virtualTourEmbed
          }
        }
      }
      description
      secondaryDescription
      locationSolutionsCollection(limit:10) {
        items {
          solution {
            name
            mobileName
            subtitle
          }
          
          optionsCollection(limit:10) {
            items {
              name
              mobileName
              slug
              subtitle
              image {
                url
                description
              }
            }
          }
        }
      }
      bikewalkDirections
      drivingDirections
      transitDirections
      communityManager {
        name
        avatar {
          url
        }
        biography
      }
      amenitiesShort
      featuredAmenitiesCollection {
        items {
          title
          image {
            url
            description
          }
        }
      }
      serviceAmenitiesCollection {
        items {
          title
          description
        }
      }
      spaceAmenitiesCollection {
        items {
          title
          description
        }
      }
      techAmenitiesCollection {
        items {
          title
          description
        }
      }
      nearbyLocationsCollection {
        items {
          name
          slug
          address1
          address2
          city
          province
          postalCode
          featuredImage {
            url
          }
        }
      }
    }
  }
}
`;

const locationsQuery = () => `
query {
    contentTypeLocationCollection(order: sortOrder_ASC limit: 10) {
        items {
            name
            slug
            sortOrder
            address1
            address2
            city
            postalCode
            province
            country
            latitude
            longitude
            acuityCalendarId
            amenitiesShort
            featuredImage {
                url
                description
                title
            }
            document {
              url
            }
            locationSolutionsCollection(limit:10) {
              items {
                solution {
                  name
                  mobileName
                  subtitle
                }
                
                optionsCollection(limit:10) {
                  items {
                    name
                    mobileName
                    slug
                    subtitle
                  }
                }
              }
            }
        }
    }
}
`;

export async function getLocationBySlug(slug) {
  const data = await fetchContent(locationQuery(slug));

  if (!data) return null;

  return data.data.contentTypeLocationCollection.items[0];
}

export async function getLocations() {
  const data = await fetchContent(locationsQuery());

  if (!data) return [];

  return data.data.contentTypeLocationCollection.items;
}
