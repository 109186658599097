import React from "react";
import clsx from "clsx";

import styles from "./button.module.scss";

const Button = React.forwardRef(function Button(
  {
    className,
    outline,
    rounded,
    small,
    mini,
    dark,
    clear,
    children,
    type = "button",
    ...props
  },
  ref
) {
  return (
    <button
      className={clsx(
        styles.Button,
        outline && styles.Outline,
        rounded && styles.Rounded,
        small && styles.Small,
        mini && styles.Mini,
        dark && styles.Dark,
        clear && styles.Clear,
        className
      )}
      type={type}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});

export default Button;
