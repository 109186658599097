import { createContext, useState } from "react";

export const BookTourFormContext = createContext();

export function BookTourFormContextProvider({ children }) {
  const [bookTourFormOpen, setBookTourFormOpen] = useState(false);

  const value = {
    bookTourFormOpen,
    setBookTourFormOpen
  };

  return (
    <BookTourFormContext.Provider value={value}>
      {children}
    </BookTourFormContext.Provider>
  );
}
