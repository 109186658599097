import config from "../config";

export async function fetchContent(query, preview = false) {
  try {
    const response = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${config.CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            preview
              ? config.CONTENTFUL_PREVIEW_TOKEN
              : config.CONTENTFUL_ACCESS_TOKEN
          }`
        },
        body: JSON.stringify({ query })
      }
    );

    const data = await response.json();

    return data;
  } catch (e) {
    console.log(e);
    console.log(`There was a problem fetching content with query ${query}`);
    return;
  }
}
