import React from "react";

export const Viewport = {
  PHONE: "PHONE",
  TABLET: "TABLET",
  DESKTOP: "DESKTOP",
  WIDE: "WIDE"
};

function getDeviceConfig(width) {
  if (width < 768) {
    return Viewport.PHONE;
  } else if (width < 1024) {
    return Viewport.TABLET;
  } else if (width < 1536) {
    return Viewport.DESKTOP;
  }

  return Viewport.WIDE;
}

export default function useViewport() {
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    setWidth(window.innerWidth);

    const handleWindowResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return getDeviceConfig(width);
}
