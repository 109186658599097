import React, { useState } from "react";
import clsx from "clsx";

import IconArrowDown from "../../assets/icons/arrow-down.svg";

import styles from "./select.module.scss";

const Select = React.forwardRef(function Select(
  {
    id,
    type = "text",
    className,
    label,
    name,
    helpText,
    errorText,
    successText,
    children,
    inline = false,
    onChange,
    ...props
  },
  ref
) {
  const [value, setValue] = useState(null);

  return (
    <div className={clsx(styles.FormGroup, inline && styles.Inline)}>
      {label && <label htmlFor={name}>{label}</label>}

      <div className="relative">
        <select
          className={clsx(
            styles.Select,
            errorText && "is-invalid",
            successText && "is-valid",
            !value && styles.Empty,
            className
          )}
          type={type}
          name={name}
          id={id || name}
          ref={ref}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e);
          }}
          {...props}
        >
          {children}
        </select>
        <IconArrowDown className={styles.IconArrow} rel="presentation" />
      </div>

      {helpText && <small className="form-text text-muted">{helpText}</small>}

      {errorText && <div className="text-danger">{errorText}</div>}

      {successText && <div className="text-success">{successText}</div>}
    </div>
  );
});

export default Select;
