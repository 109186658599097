import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import Drift from "react-driftjs";
import TagManager from "react-gtm-module";

import pkg from "../package.json";
import config from "../config";

import BookingFormModal from "../components/booking-form-modal";
import Layout from "../components/layout";

import "../styles/global.scss";
import {
  MobileMenuContext,
  MobileMenuContextProvider,
} from "../hooks/useMobileMenu";
import {
  BookTourFormContext,
  BookTourFormContextProvider,
} from "../hooks/useBookTourForm";
import useViewport, { Viewport } from "../hooks/useViewport";

function App({ Component, pageProps }) {
  const router = useRouter();
  const setMobileMenuOpen = useContext(MobileMenuContext)[1];
  const { bookTourFormOpen, setBookTourFormOpen } =
    useContext(BookTourFormContext);

  useEffect(() => {
    console.log(
      "%cEast Room",
      "background: green; color: white;",
      `v${pkg.version}`
    );

    if (config.DEBUG) {
      console.log("config", config);
    }

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      try {
        e.prompt();
      } catch (e) {
        // Ignore prompt failure.
      }
    });

    TagManager.initialize({ gtmId: config.GOOGLE_TAG_MANAGER_ID });
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      window.scroll({
        top: 0,
        left: 0,
      });
    };

    const handleRouteChangeStart = () => {
      setMobileMenuOpen(false);
      setBookTourFormOpen(false);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, setMobileMenuOpen, setBookTourFormOpen]);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(config.FACEBOOK_PIXEL_ID);
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  const viewport = useViewport();

  return (
    <Layout>
      <Component {...pageProps} />

      <BookingFormModal
        lightbox={viewport !== Viewport.PHONE}
        isOpen={bookTourFormOpen}
        onRequestClose={() => setBookTourFormOpen(false)}
      />
    </Layout>
  );
}

function AppWithContext({ ...props }) {
  useEffect(() => {
    if (window) {
      window.drift_init_options = { loadType: "ON_INTERACTIVE" };
    }
  }, []);
  return (
    <MobileMenuContextProvider>
      <BookTourFormContextProvider>
        <>
          <App {...props} />

          <Drift appId={config.DRIFT_APP_ID} />

          <Script id="google-analytics" strategy="afterInteractive">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${config.GOOGLE_TAG_MANAGER_ID}');`}
          </Script>
        </>
      </BookTourFormContextProvider>
    </MobileMenuContextProvider>
  );
}

export default AppWithContext;
