import { createContext, useState } from "react";

export const MobileMenuContext = createContext();

export function MobileMenuContextProvider({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const value = [mobileMenuOpen, setMobileMenuOpen];

  return (
    <MobileMenuContext.Provider value={value}>
      {children}
    </MobileMenuContext.Provider>
  );
}
