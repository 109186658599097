import Link from "next/link";

import NewsletterForm from "../newsletter-form";

import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.FooterWrapper}>
      <NewsletterForm />
      <div className={styles.Footer}>
        <div className="container">
          <div className={styles.Menus}>
            <div className={styles.SiteMenu}>
              <div className={styles.Menu}>
                <h3>Company</h3>
                <ul>
                  <li>
                    <Link href="/locations">Locations</Link>
                  </li>
                  <li>
                    <Link href="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link href="/inclusion-and-diversity">
                      Inclusion &amp; Diversity
                    </Link>
                  </li>
                  <li>
                    <Link href="/careers">Careers</Link>
                  </li>
                </ul>
              </div>

              <div className={styles.Menu}>
                <h3>Offerings</h3>
                <ul>
                  <li>
                    <Link href="/solutions/open-space">Open Space</Link>
                  </li>
                  <li>
                    <Link href="/solutions/private-space">Private Space</Link>
                  </li>
                  <li>
                    <Link href="/solutions/spaces-for-hire">
                      Spaces for Hire
                    </Link>
                  </li>
                </ul>
              </div>

              <div className={styles.Menu}>
                <h3>Partnership</h3>
                <ul>
                  <li>
                    <Link href="/brokers">Brokers</Link>
                  </li>
                  <li>
                    <Link href="/referral-program">Refer a Friend</Link>
                  </li>
                  <li>
                    <Link href="/events">Event Planners</Link>
                  </li>
                  <li>
                    <Link href="/film-and-photography">
                      Film &amp; Photography
                    </Link>
                  </li>
                </ul>
              </div>

              <div className={styles.Menu}>
                <h3>Support</h3>
                <ul>
                  <li>
                    <Link href="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link href="/downloads">Downloads</Link>
                  </li>
                  <li>
                    <Link href="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.SocialMenu}>
              <h3>Socials</h3>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/eastroom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/east-room/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/EastRoomTO/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://www.youtube.com/channel/UCEgxRkiHD16Rjk8AufGLzAA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.tiktok.com/@eastroom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TikTok
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://soundcloud.com/eastroom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Soundcloud
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.Legal}>
            <p className={styles.LegalCopy}>
              Copyright East Room. All rights reserved.
            </p>
            <p>
              <Link href="/privacy-policy">Privacy Policy</Link> and{" "}
              <Link href="/terms">Terms</Link>. Update your{" "}
              <Link href="/cookie-policy">Cookie Preferences</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
