import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { format, isSameDay, parseISO } from "date-fns";
import Link from "next/link";
import { useForm } from "react-hook-form";
import TagManager from "react-gtm-module";

import {
  useAcuityAvailableDates,
  useAcuityAvailableTimes,
  useAcuityForms
} from "../../hooks/useAcuitySchedule";
import { useLocations } from "../../hooks/useLocations";
import useViewport, { Viewport } from "../../hooks/useViewport";

import IconCross from "../../assets/icons/icon-cross.svg";
import AcuityFormInput from "../acuity-form-input";
import Button from "../button";
import Input from "../input";
import Select from "../select";
import Modal from "../modal";
import DateSelect from "../date-select";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";

import styles from "./booking-form-modal.module.scss";
import { BookTourFormContext } from "../../hooks/useBookTourForm";

function isDayDisabled(day, enabledDays) {
  return !enabledDays
    .map(({ date }) => parseISO(date))
    .some((enabledDay) => isSameDay(day, enabledDay));
}

// Membership field in Acuity to overwrite options
// with the ones from the selected location.
const membershipFieldId = 11264014;

const BookingForm = ({ ...props }) => {
  const [bookTourFormOpen] = useContext(BookTourFormContext);

  const {
    register,
    formState,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset
  } = useForm({
    mode: "onChange",
    defaultValues: {
      location: "",
      tourDate: "",
      datetime: ""
    }
  });

  const { data: locations } = useLocations();
  const { data: dates } = useAcuityAvailableDates(getValues().location);
  const { data: times } = useAcuityAvailableTimes(
    getValues().location,
    getValues().tourDate
  );
  const { data: forms, loading: fieldsLoading } = useAcuityForms();

  watch();

  const onSubmit = (data) => {
    const copyData = {
      ...data
    };
    const fields = Object.entries(copyData)
      .filter(([key]) => key.includes("field:"))
      .map(([key, value]) => {
        const fieldId = key.split(":")[1];
        delete copyData[key];
        const foundFormField = forms[0].fields?.find(
          (field) => field.id === parseInt(fieldId)
        );

        if (foundFormField?.type === "yesno") {
          value = value ? "yes" : "no";
        }
        return { id: fieldId, value };
      });

    copyData.fields = fields;

    const trackingData = {
      event: "book_tour",
      firstname: copyData.fields.firstName,
      lastname: copyData.fields.lastName,
      ...copyData.fields
    };

    delete trackingData.firstName;
    delete trackingData.lastName;

    TagManager.dataLayer({
      dataLayer: trackingData
    });

    const selectedLocation = locations.find(
      (loc) => loc.acuityCalendarId === getValues().location
    );

    if (selectedLocation) {
      copyData.calendarID = parseInt(selectedLocation.acuityCalendarId);
    }

    fetch("/api/appointment", {
      method: "POST",
      body: JSON.stringify(copyData)
    }).then(() => {
      reset();
      props.onSubmit && props.onSubmit();
    });
  };

  const onError = (e) => console.log(e);

  const { errors, isDirty } = formState;

  useEffect(() => {
    if (forms && forms[0] && forms[0].fields && !isDirty) {
      forms[0].fields.map((field) => {
        setValue(`field:${field.id}`, "", {
          shouldDirty: false,
          shouldValidate: false,
          shouldTouch: false
        });
      });
    }
  }, [forms, isDirty, setValue]);

  useEffect(() => {
    if (!bookTourFormOpen) {
      // If not open, reset.
      reset();
    }
  }, [bookTourFormOpen, reset]);

  const currentLocation = locations.find(
    (loc) => loc.acuityCalendarId === getValues().location
  );

  if (fieldsLoading) return null;

  return (
    <form className={clsx("p-6")} onSubmit={handleSubmit(onSubmit, onError)}>
      <h2 className="font-bold text-3xl text-center">Book a Tour</h2>

      <div className="my-4">
        <div className="form-group-inline">
          <Input
            type="text"
            placeholder="First name *"
            {...register("firstName", {
              required: "First name is required"
            })}
            errorText={errors.firstName && errors.firstName.message}
            inline
          />
          <Input
            type="text"
            placeholder="Last name *"
            {...register("lastName", { required: "Last name is required" })}
            errorText={errors.lastName && errors.lastName.message}
            inline
          />
        </div>

        <Input
          type="text"
          placeholder="Work email address *"
          {...register("email", { required: "Email is required" })}
          errorText={errors.email && errors.email.message}
        />

        <Input
          type="text"
          placeholder="Phone number *"
          {...register("phone", {
            required: "Phone number is required"
          })}
          errorText={errors.phone && errors.phone.message}
        />

        <Select
          {...register("location", {
            required: "Location is required"
          })}
          errorText={errors.location && errors.location.message}
        >
          <option value="" disabled>
            Select your location of interest *
          </option>
          {locations.map((location) => (
            <option value={location.acuityCalendarId} key={location.slug}>
              {location.name}
            </option>
          ))}
        </Select>
        <div className="form-group-inline">
          <DateSelect
            placeholder="Tour date *"
            selectedDate={getValues().tourDate}
            disabledDates={(day) => isDayDisabled(day, dates)}
            {...register("tourDate", {
              required: "Tour date is required"
            })}
            onChange={(day) => {
              setValue("tourDate", day.toISOString(), {
                shouldValidate: true
              });
            }}
            inline
            errorText={errors.tourDate && errors.tourDate.message}
          />

          <Select
            type="text"
            placeholder="Tour time *"
            {...register("datetime", { required: "Tour time is required" })}
            errorText={errors.datetime && errors.datetime.message}
            inline
          >
            <option value="" disabled>
              Tour time *
            </option>
            {times?.map(({ time }) => (
              <option key={time} value={time}>
                {format(new Date(time), "h:mm aa")}
              </option>
            ))}
          </Select>
        </div>

        {forms[0]?.fields?.map((field) => {
          // check form calendar id matches selected location

          if (currentLocation && field.id === membershipFieldId) {
            // Override the membership field to show location specific memberships

            let allOptions = [];
            for (let solution of currentLocation.locationSolutionsCollection
              .items) {
              allOptions = [...allOptions, ...solution.optionsCollection.items];
            }

            field.options = allOptions.map((opt) => opt.name);

            return (
              <AcuityFormInput
                key={field.id}
                field={field}
                {...register(`field:${field.id}`, {
                  required: field.required && "This field is required"
                })}
                required={field.required}
                errorText={
                  errors[`field:${field.id}`] &&
                  errors[`field:${field.id}`].message
                }
              />
            );
          }
          return (
            <AcuityFormInput
              key={field.id}
              field={field}
              {...register(`field:${field.id}`, {
                required: field.required && "This field is required"
              })}
              required={field.required}
              errorText={
                errors[`field:${field.id}`] &&
                errors[`field:${field.id}`].message
              }
            />
          );
        })}
      </div>

      <div className=" text-sm">
        By clicking the button below you agree to our{" "}
        <Link href="/terms" passHref>
          <a className="underline">Terms of Service</a>
        </Link>
      </div>

      <Button type="submit" className="w-full my-4" outline>
        Book
      </Button>
    </form>
  );
};

const BookingFormModal = ({ onRequestClose, isOpen, ...props }) => {
  const device = useViewport();
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        {...props}
      />
    );
  }

  if (device !== Viewport.PHONE) {
    // Animate from out of website bounds top right.
    return (
      <div className={clsx(styles.AnimatedForm, isOpen && styles.Open)}>
        <>
          <BookingForm onSubmit={() => setSubmitted(true)} />
          <Button
            className={clsx(styles.CloseButton)}
            clear
            onClick={() => onRequestClose()}
          >
            <IconCross width="19" className="print:hidden" />
          </Button>
        </>
      </div>
    );
  }

  return (
    <Modal
      {...props}
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
    >
      <BookingForm onSubmit={() => setSubmitted(true)} />
    </Modal>
  );
};

export default BookingFormModal;
