import clsx from "clsx";
import { useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import Logo from "../../assets/graphics/logo.svg";
import IconMobileMenu from "../../assets/icons/mobile-menu.svg";
import IconMobileMenuClose from "../../assets/icons/mobile-menu-close.svg";
import UserIcon from "../../assets/icons/user.svg";

import { BookTourFormContext } from "../../hooks/useBookTourForm";
import { MobileMenuContext } from "../../hooks/useMobileMenu";

import styles from "./nav.module.scss";

const MobileNav = () => {
  const router = useRouter();
  const [showMobileMenu, setShowMobileMenu] = useContext(MobileMenuContext);
  const { setBookTourFormOpen } = useContext(BookTourFormContext);

  return (
    <>
      <nav className={styles.MobileNav}>
        <div
          role="button"
          className={styles.MobileToggle}
          onClick={() => setShowMobileMenu((v) => !v)}
        >
          {!showMobileMenu && <IconMobileMenu role="presentation" />}
          {showMobileMenu && <IconMobileMenuClose role="presentation" />}
        </div>
        <div>
          <Link href="/" passHref>
            <a aria-label="East Room" className={styles.MobileLogo}>
              <Logo width="119" role="presentation" className="block mt-2" />
            </a>
          </Link>
        </div>
        <div className={styles.MobileRightNav}>
          {router.pathname !== "/contact" && (
            <Link href="/contact">Contact</Link>
          )}
          {router.pathname === "/contact" && (
            <a onClick={() => router.back()}>Contact</a>
          )}
        </div>
      </nav>

      {showMobileMenu && (
        <div className={styles.MobileMenu}>
          <div>
            <ul>
              <li>
                <Link href="/" passHref>
                  <a>Home</a>
                </Link>
              </li>
              <li>
                <Link href="/solutions" passHref>
                  <a>Solutions</a>
                </Link>
              </li>
              <li>
                <Link href="/locations" passHref>
                  <a>Locations</a>
                </Link>
              </li>
              <li>
                <Link href="/news" passHref>
                  <a>News &amp; Stories</a>
                </Link>
              </li>
              <li>
                <a onClick={() => setBookTourFormOpen(true)}>Book a Tour</a>
              </li>
              <li>
                <Link href="/login" passHref>
                  <a>Log In</a>
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.Contact}>
            <p>Have questions or need help?</p>
            <p>
              <a href="tel:+14166285333">(416) 628-5333</a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const DesktopNav = () => {
  const { setBookTourFormOpen } = useContext(BookTourFormContext);
  return (
    <nav className={styles.DesktopNav}>
      <div className={clsx("container", styles.DesktopWrapper)}>
        <div className={styles.DesktopLeftNav}>
          <Link href="/solutions">Solutions</Link>
          <Link href="/locations">Locations</Link>
          <Link href="/news">News &amp; Stories</Link>
          <Link href="/contact">Contact</Link>
        </div>
        <div>
          <Link href="/" passHref>
            <a>
              <Logo width="167" role="presentation" />
            </a>
          </Link>
        </div>
        <div className={styles.DesktopRightNav}>
          <a onClick={() => setBookTourFormOpen(true)}>Book a Tour</a>
          <Link href="/login" passHref>
            <a>
              <div>Log In</div>
              <div className={styles.UserIcon}>
                <UserIcon role="presentation" width="16" />
              </div>
            </a>
          </Link>
        </div>
      </div>
    </nav>
  );
};

const Nav = () => {
  return (
    <>
      <MobileNav />

      <DesktopNav />
    </>
  );
};

export default Nav;
