import { useState } from "react";
import { useForm } from "react-hook-form";
import TagManager from "react-gtm-module";

import ArrowRight from "../../assets/icons/arrow-right.svg";
import Button from "../button";
import ConfirmationModal from "../confirmation-modal/confirmation-modal";

import styles from "./newsletter-form.module.scss";
import config from "../../config";

const NewsletterForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const { register, handleSubmit, watch, reset } = useForm({
    mode: "onChange",
    // Default Active Campaign values for this
    // specific form.
    defaultValues: {
      email: "",
      emailMobile: "",
      u: "13",
      f: "13",
      s: "0",
      c: "0",
      m: "0",
      act: "sub",
      v: "2"
    }
  });

  watch();

  const onSubmit = (data) => {
    const formData = new FormData();

    if (data.emailMobile) {
      data.email = data.emailMobile;
      delete data.emailMobile;
    }

    for (const key in data) {
      formData.append(key, data[key]);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "newsletter-optin",
        email: data.email
      }
    });

    fetch(config.CONTACT_FORM_ENDPOINT, {
      method: "POST",
      body: formData,
      mode: "no-cors"
    })
      .then(() => {
        setSubmitted(true);
        reset();
      })
      .catch((e) => setError(e));
  };

  const onError = (e) => {
    setError(e.email.message);
  };

  return (
    <div className={styles.NewsletterFormSection}>
      <div className="container">
        <div className={styles.NewsletterFormMobile}>
          <div className={styles.FormGroup}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <input
                type="email"
                placeholder={
                  submitted
                    ? "Thank you! We have sent you a confirmation email"
                    : "Subscribe to our newsletter"
                }
                aria-label="Subscribe to our newsletter"
                {...register("emailMobile")}
              />

              <Button
                className={styles.Button}
                clear
                type="submit"
                aria-label="Subscribe"
              >
                <ArrowRight width="13" role="presentation" />
              </Button>
            </form>
          </div>
        </div>
        <div className={styles.NewsletterFormDesktop}>
          <div className={styles.Label}>
            <h3>Subscribe to our newsletter</h3>
          </div>
          <div className={styles.FormGroup}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <input
                type="email"
                placeholder={
                  submitted
                    ? "Thank you! We have sent you a confirmation email"
                    : "Enter your email address"
                }
                {...register("email", {
                  required: {
                    message: "Please enter a valid email."
                  }
                })}
              />

              <Button
                className={styles.Button}
                clear
                type="submit"
                aria-label="Subscribe"
              >
                <ArrowRight width="13" role="presentation" />
              </Button>
            </form>
          </div>
        </div>
      </div>

      {error && (
        <ConfirmationModal
          lightbox
          isOpen
          onRequestClose={() => setError(null)}
          title="Oops!"
          message={error}
        />
      )}
    </div>
  );
};

export default NewsletterForm;
