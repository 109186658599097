import { useEffect } from "react";

export default function useClickOutside(handler, referrence) {
  useEffect(() => {
    function handleClickOutside(e) {
      if (referrence.current && !referrence.current.contains(e.target)) {
        handler();
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => window.removeEventListener("mousedown", handleClickOutside);
  }, [handler, referrence]);
}
