import clsx from "clsx";
import ReactModal from "react-modal";

import IconCross from "../../assets/icons/icon-cross.svg";
import Button from "../button";

import styles from "./modal.module.scss";

const customStyles = {
  overlay: {
    background: "white",
    zIndex: 1000,
    padding: 0,
    margin: 0,
  },
  content: { inset: 0, border: 0, margin: 0, padding: 0, paddingTop: "2rem" },
};

const lightboxStyle = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1000,
    padding: 0,
    margin: 0,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    border: "0",
  },
};

const Modal = ({ children, onRequestClose, lightbox, ...props }) => {
  return (
    <ReactModal
      style={lightbox ? lightboxStyle : customStyles}
      onRequestClose={onRequestClose}
      portalClassName={lightbox ? "LightboxPortal" : ""}
      {...props}
    >
      {children}
      <Button
        className={clsx(
          styles.CloseButton,
          lightbox && styles.CloseButtonLightbox
        )}
        clear
        onClick={() => onRequestClose()}
      >
        <IconCross width="19" className="print:hidden" />
      </Button>
    </ReactModal>
  );
};

export default Modal;
