const defaultConfig = {
  // Contentful
  CONTENTFUL_SPACE_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  CONTENTFUL_ACCESS_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN,

  // Google Maps
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_MAP_ID: "cfbf351234dbae29",

  // Facebook
  FACEBOOK_PIXEL_ID: "467522431133167",

  // Google Analytics
  GOOGLE_ANALYTICS_UA: "G-DS9QXNXJYW",
  GOOGLE_TAG_MANAGER_ID: "GTM-TSTS2XW",

  // LinkedIn Insights
  LINKEDIN_INSIGHTS_ID: "",

  // Drift chat app.
  DRIFT_APP_ID: "2b9st37kxvsy",

  // Mapbox
  MAPBOX_TOKEN:
    "pk.eyJ1IjoiZXJta3RnIiwiYSI6ImNsMzlpdW5oeDA1emUzZ2xydHBxejk3ZWsifQ.yvaVuxXXN6ZZQSlZFJdpTQ",
  MAPBOX_STYLE: "mapbox://styles/ermktg/cl31nvixp000615mnntu8hd6c",

  // Next.js will attempt to re-generate the page:
  // - When a request comes in
  // - At most once every 60 seconds
  ISR_TIMING: 60, // 60 second incremental static regneration.

  // CONTACT_FORM_ENDPOINT: "/api/contact", // Local form submission
  CONTACT_FORM_ENDPOINT: "https://eastroom.activehosted.com/proc.php", // Remote form submission

  CONTACT_FORM_EMAIL: "danielle@eastroom.com"
};

const config = {
  development: {
    ...defaultConfig,
    DEBUG: true,
    BASE_URL: "https://www.eastroom.com"
  },
  production: { ...defaultConfig, BASE_URL: "https://www.eastroom.com" }
};

export default config[process.env.NODE_ENV || "development"];
