import React from "react";
import clsx from "clsx";

import styles from "./checkbox.module.scss";

const Checkbox = React.forwardRef(function Checkbox(
  { id, className, label, name, helpText, errorText, successText, ...props },
  ref
) {
  return (
    <div className={styles.FormGroup}>
      <label htmlFor={name}>
        <input
          type="checkbox"
          className={clsx(
            styles.Checkbox,
            errorText && "is-invalid",
            successText && "is-valid",
            className
          )}
          name={name}
          id={id || name}
          ref={ref}
          {...props}
        />
        {label}
      </label>

      {helpText && <small className="form-text text-muted">{helpText}</small>}

      {errorText && <div className="text-danger">{errorText}</div>}

      {successText && <div className="text-success">{successText}</div>}
    </div>
  );
});

export default Checkbox;
