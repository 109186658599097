import React from "react";

import Checkbox from "../checkbox";
import Input from "../input";
import Select from "../select";
import Textarea from "../textarea";

const AcuityFormInput = React.forwardRef(function FormInput(
  { field, required, ...props },
  ref
) {
  if (field.type === "dropdown") {
    return (
      <Select key={field.id} name={`field:${field.id}`} ref={ref} {...props}>
        <option key={field.name} value="" disabled>
          {field.name + (required ? " *" : "")}
        </option>
        {field.options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    );
  }

  if (
    field.type === "text" ||
    (field.type === "textbox" && field.lines === 1)
  ) {
    return (
      <Input
        type="text"
        name={`field:${field.id}`}
        placeholder={field.name + (required ? " *" : "")}
        ref={ref}
        {...props}
      />
    );
  }

  if (field.type === "textbox" && field.lines > 1) {
    return (
      <Textarea
        name={`field:${field.id}`}
        placeholder={field.name + (required ? " *" : "")}
        ref={ref}
        rows={field.lines}
        {...props}
      />
    );
  }

  if (field.type === "checkbox") {
    return (
      <Checkbox
        name={`field:${field.id}`}
        label={field.name}
        ref={ref}
        {...props}
      />
    );
  }

  if (field.type === "yesno") {
    return (
      <>
        <Checkbox
          name={`field:${field.id}`}
          label={field.name}
          ref={ref}
          {...props}
        />
      </>
    );
  }

  return null;
});

export default AcuityFormInput;
