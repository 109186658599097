import Modal from "../modal";

import styles from "./confirmation-modal.module.scss";

const ConfirmationModal = ({
  title = "Thank you!",
  message = "A member of our team will be in touch with you shortly!",
  ...props
}) => {
  return (
    <Modal {...props}>
      <>
        <h2 className={styles.Title}>{title}</h2>

        <p className={styles.Message}>{message}</p>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
